.button {
	width: 25px;
	height: 25px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.button div {
	background-color: #386fb1;
	height: 3px;
	border-radius: 3px;
}

.open .button div:nth-child(1) {
	transform: rotate(45deg) scale(1.3) translate(4.5px, 4.5px);
}
.open .button div:nth-child(2) {
	transition-property: opacity;
	opacity: 0;
}
.open .button div:nth-child(3) {
	transform: rotate(-45deg) scale(1.3) translate(4.5px, -4.5px);
}

.button,
.button div,
.menu,
.overlay {
	transition-duration: 0.4s;
}

@media (max-width: 767.5px) {
	.overlay {
		position: fixed;
		z-index: 1000;
		inset: 0;
		pointer-events: none;
	}
	.menu,
	.overlay {
		top: 75px;
	}
	.menu {
		position: fixed;
		z-index: 1001;
		right: 0;
		bottom: 0;
		transform: translateX(100%);

		width: 300px;
		max-width: 100%;
		display: flex;
		flex-direction: column;
		background-color: #fff;
		font-size: 18px;
		overflow-y: auto;
	}
	.open .menu {
		transform: translateX(0);
	}
	.open .overlay {
		background-color: rgba(0, 0, 0, 0.75);
		pointer-events: all;
	}
}

@media (min-width: 768px) {
	.button,
	.overlay {
		display: none;
	}
	.menu {
		display: flex;
		column-gap: 32px;
	}
}
