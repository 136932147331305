.arrow::before {
	content: "";
	position: absolute;
	bottom: 100%;
	left: 50%;
	margin-left: -7px;
	border-width: 7px;
	margin-bottom: -1px;
	border-style: solid;
	border-color: transparent transparent var(--primary-color) transparent;
	transform: scale(0);
	transform-origin: bottom;
	transition: all 250ms;
}

.arrow.open::before {
	transform: scale(1);
}
