@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--primary-color: #386fb1;
	--primary-dark-color: #2c578b;
}

* {
	transition: 0.05s linear all;
}

@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("../public/fonts/Roboto-Regular.ttf");
}

@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url("../public/fonts/Roboto-Italic.ttf");
}

@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("../public/fonts/Roboto-Bold.ttf");
}

html,
body {
	font-family: Roboto, ui-sans-serif, -apple-system, BlinkMacSystemFont, sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

#chat-application {
	z-index: 55 !important;
}

input[type=checkbox] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	width: 16px;
	height: 16px;
	border: 1px solid var(--primary-dark-color);
	outline: none;
	cursor: pointer;
}

input[type=checkbox]:disabled {
	cursor: not-allowed;
	border: 1px solid #8c8c8c;
	background: #dcdcdc;
}

input[type=checkbox]:checked {
	background: var(--primary-color);
	opacity: 0.55;
}

input[type=radio] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	width: 16px;
	height: 16px;
	border: 1px solid var(--primary-dark-color);
	outline: none;
	cursor: pointer;
	border-radius: 50%;
}

input[type=radio]:disabled {
	cursor: not-allowed;
	border: 1px solid #8c8c8c;
	background: #dcdcdc;
}

input[type=radio]:checked {
	background: var(--primary-color);
	opacity: 0.55;
}

@layer base {
	h1 {
		@apply text-2xl;
	}

	h2 {
		@apply font-semibold text-xl pb-12 pl-4;
	}

	h4 {
		@apply font-semibold text-lg pb-4;
	}

	.panel {
		@apply border h-fit p-16 max-h-screen bg-white rounded-lg overflow-y-auto;
	}

	.panel-calc {
		@apply border h-fit py-20 bg-white rounded-lg;
	}

	.cards {
		@apply max-h-screen overflow-y-auto;
	}

	.card {
		@apply border mt-12 p-16 bg-white rounded-lg;
	}

	body {
		@apply bg-background;
	}

	.preview-component {
		@apply grid grid-cols-9 pb-8;
	}

	.preview-component__data__input__wrapper--inner {
		@apply py-4 px-8 rounded-md border border-primary text-primary relative;
	}

	select.preview-component__data__input__wrapper--inner {
		@apply w-full h-[42px] box-border m-0 mb-4 focus:outline-none;
	}

	.preview-component .preview-component__data__input {
		@apply w-full;
	}

	.preview-component .preview-component__data {
		@apply col-span-7;
	}

	.preview-component .preview-component__data__heading {
		@apply mb-4 text-black-full font-medium;
	}

	.download {
		@apply bg-primary text-white border border-primary hover:bg-white hover:text-primary py-8 px-12 rounded-sm w-full my-4;
	}

	.slider {
		@apply w-max min-w-full max-w-full bg-black-300 accent-primary border-none outline-none appearance-none h-6 rounded;
	}

	.preview-component__data__suffix {
		@apply right-4 mt-4 absolute;
	}
}